<template>
  <div>
    <shipblu-table
      multiple
      v-model="selected"
      :sst="true"
      :data="warehouseUnits"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Bin Name')}}</shipblu-th>
        <shipblu-th>{{$t('Scanned Successfully')}}</shipblu-th>
        <shipblu-th>{{$t('Orders In-Bag')}}</shipblu-th>
        <shipblu-th>{{$t('Count of Master Bags')}}</shipblu-th>
        <shipblu-th class="flex justify-center"><feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/></shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-packages-in-bin`, params: { warehouseID: $route.params.warehouseID, unitID: data[indextr].id }}">
          <shipblu-td class="order-7" :data="data[indextr].aisleSign">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              {{ data[indextr].aisle_sign }}
            </div>
          </shipblu-td>

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].numPackages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Scanned Successfully')}}</p>
            {{ data[indextr].num_packages}}
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].ordersInBag">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Orders In-Bag')}}</p>
            {{ data[indextr].orders_in_bags}}
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].bags">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Count of Master Bags')}}</p>
            {{ data[indextr].count_of_master_bags}}
          </shipblu-td>

          <shipblu-td class="order-2">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
              >
              <span class="material-symbols-outlined text-2xl">more_horiz</span> 
              <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="createBag(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="ShoppingBagIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Create Bag')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="viewPackagesInUnit(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View Bin')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <add-bag :sealData="sealData" :sealFlag="sealFlag" :warehousesDic="warehousesDic" @sealFlag="sealFlag = $event"/>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import AddBag from './components/AddBag.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'

export default {
  data () {
    return {
      warehouseUnits: [],
      tableLoader: false,
      selected: [],
      slot: {},
      type: 'add',
      binData: {},
      warehousesDic: {},
      sealFlag: false,
      types: [
        {
          name: 'Normal',
          value: 'normal'
        },
        {
          name: 'Fragile',
          value: 'fragile'
        }
      ],
      sealData: {}
    }
  },
  watch: {
    '$route.params.warehouseID' () {
      this.loadWarehousesUnits()
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    loadWarehouses () {
      sendRequest(false, false, this, 'api/v1/warehouses/?limit=100', 'get', null, true,
        (response) => {
          response.data.results.forEach(warehouse => {
            this.warehousesDic[warehouse.id] = warehouse.code
          })
        }
      )
    },
    loadWarehousesUnits () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/bins/?warehouse__code=${this.$route.params.warehouseID}&limit=1000`, 'get', null, true,
        (response) => {
          this.selected = []
          this.warehouseUnits = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    confirmEmpty (slot) {
      this.slot = slot
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure to empty this slot?',
        accept: this.emptySlot
      })
    },
    createBag (bin) {
      this.sealFlag = true
      this.sealData = bin
      this.sealData.bag_type = 'normal'
    },
    emptySlot () {
      sendRequest(false, false, this, `api/v1/warehousing/bins/${this.slot.id}/empty/`, 'post', {}, true,
        () => {
          this.loadWarehousesUnits()
        }
      )
    },
    viewPackagesInUnit (unit) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-packages-in-bin`,
        params: {
          warehouseID: this.$route.params.warehouseID,
          unitID: unit.id
        }
      }).catch(() => {})
    }
  },
  components: {
    vSelect,
    AddBag,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  created () {
    this.loadWarehouses()
    this.loadWarehousesUnits()
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-vs-dropdown--menu {
  z-index: 999999;
}
</style>
