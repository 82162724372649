<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="sealFlag"
      :title="$t('Create Bag')"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('Seal No.')" name="seal number" v-model="sealData.code" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('seal number')">{{ errors.first('seal number') }}</span>
          </div>
          <div class="vx-col w-1/2">
          <v-select :disabled="true" :options="types" class="mt-10 w-full" name="bag type" label="name"
            v-model="sealData.bag_type" :placeholder="$t('Bag Type')"></v-select>
            <span class="text-danger text-sm" v-show="errors.has('bag Type')">{{ errors.first('bag Type') }}</span>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required'" :label-placeholder="$t('From')" name="from" :disabled="true" v-model="warehousesDic[sealData.warehouse]" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required'" :label-placeholder="$t('To')" name="to" :disabled="true" v-model="warehousesDic[sealData.to_warehouse]" class="mt-10 w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
          </div>
        </div>
        <h6 class="mt-4">{{$t('You can scan packages after creating a bag.')}}</h6>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="sealBin" class="active-btn btn">{{ $t('Create Bag') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import {sendRequest} from '../../../http/axios/requestHelper.js'

export default {
  props: ['sealData', 'sealFlag', 'warehousesDic'],
  data () {
    return {
      types: [
        {
          name: 'Normal',
          value: 'normal'
        },
        {
          name: 'Fragile',
          value: 'fragile'
        }
      ]
    }
  },
  watch: {
    selected () {
      const packages = this.selected.map(element => element.packages.map(item => item.package)).flat()
      packages.flat()
      packages.map(item => item.fragile)
      const fragile = packages.map(item => item.fragile).filter(element => element === true)
      this.sealData.bag_type = fragile.length > 0 ? 'fragile' : 'normal'
    }
  },
  methods: {
    closeModal () {
      this.$emit('sealFlag', false)
      this.selected = []
    },
    sealBin () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const sealData = {
            code: this.sealData.code,
            bin: this.sealData.id
          }
          sendRequest(false, false, this, 'api/v1/seals/', 'post', sealData, true, 
            (response) => {
              this.$router.push({
                name: 'head-of-fleet-seal-view',
                params: {
                  warehouseID: this.$route.params.warehouseID,
                  sealId: response.data.id
                }
              }).catch(() => {})
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>